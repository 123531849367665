import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Buttons } from "../../../components/button/Button";
import { H3 } from "../../../components/headline/Headline";
import { InputField } from "../../../components/input/InputFields";
import { Textarea } from "../../../components/input/Textarea";
import Select from "../../../components/select/Select";
import { Voucher } from "../../../types/Ticket";
import { PATTERN_VOUCHER_TEXT } from "../../booking/identification/types";

const CREDIT_OPTIONS: { text: string, price: number }[] = [
    {
        text: "Anfängerkarte für Jugendliche inkl. Einweisung und Equipment (48 €)",
        price: 48
    },
    {
        text: "Anfängerkarte für Erwachsene inkl. Einweisung und Equipment (53 €)",
        price: 53
    },
    {
        text: "Anfängerintensivkurs inkl. Einweisung und Equipment (62 €)",
        price: 62
    },
    {
        text: "2 Stundenkarte für Jugendliche ohne Equipment (30 €)",
        price: 30
    },
    {
        text: "2 Stundenkarte für Erwachsene ohne Equipment (35 €)",
        price: 35
    },
    {
        text: "Individueller Betrag",
        price: 20
    }
];

interface VoucherFormProps {
    onComplete: (data: Voucher) => void;
    voucher?: Voucher;
}

export default function VoucherForm({ onComplete, voucher }: VoucherFormProps) {

    const [selectedCreditOption, setSelectedCreditOption] = useState<number>(voucher ? voucher.creditOption : 0);
    const [totalSum, setTotalSum] = useState<number>(voucher?.credit || 0);

    const { register, formState: { errors }, handleSubmit, setValue, setError } = useForm<Voucher>({
        defaultValues: {
            creditOption: 0,
            credit: 0,
            headline: '',
            text: ''
        }
    });

    useEffect(() => {
        if (voucher) {
            setValue('creditOption', voucher.creditOption);
            setValue('credit', voucher.credit);
            setValue('headline', voucher.headline);
            setValue('text', voucher.text);
        }
    }, [voucher, setValue])

    return <>
        <H3 text="Konfiguriere Deinen Gutschein" />
        <form onSubmit={handleSubmit((data => {
            if (selectedCreditOption === 0) {
                setError('creditOption', { type: "required" }, { shouldFocus: true });
            }
            else {
                onComplete(data);
            }
        }))}>
            <Select
                register={register('creditOption', { required: true, min: 1, valueAsNumber: true })}
                name="creditOption"
                label="Gutscheinoption"
                options={CREDIT_OPTIONS.map((co, index) => {
                    return {
                        text: co.text,
                        value: index + 1
                    }
                })
                }
                defaultOption={'Bitte wählen'}
                onChange={
                    (value) => {
                        if (value !== 0 && value !== null) {
                            setValue("credit", CREDIT_OPTIONS[value - 1].price);
                            setTotalSum(CREDIT_OPTIONS[value - 1].price);
                        } else {
                            setTotalSum(0);
                        }
                        setSelectedCreditOption(value || 0);
                    }
                }
                hasError={Boolean(errors?.creditOption)}
                errorMessage="Bitte wähle eine Gutscheinoption aus"
            />
            {selectedCreditOption === CREDIT_OPTIONS.length &&
                <InputField
                    name="credit"
                    label="Individueller Betrag"
                    placeholder="20"
                    hasError={Boolean(errors?.credit)}
                    errorMessage="Bitte lege einen gültigen Gutscheinwert fest"
                    register={register('credit', { required: true, min: 1, valueAsNumber: true })}
                    type="number"
                    onChange={(event) => setTotalSum(parseInt(event.currentTarget.value) || 0)}
                />
            }

            <InputField
                name="headline"
                label="Überschrift (optional)"
                placeholder="Happy Birthday"
                hasError={Boolean(errors?.headline)}
                errorMessage={'Bitte gib nur gültige Zeichen ein. Erlaubt sind Groß- und Kleinbuchstaben, sowie die Sonderzeichen ,.\'-_#()!?'}
                register={register("headline", { maxLength: 25, pattern: PATTERN_VOUCHER_TEXT })}
                maxLength={25}
            />
            <Textarea
                register={register('text', { maxLength: 180, pattern: PATTERN_VOUCHER_TEXT })}
                name="text"
                hasError={Boolean(errors?.text)}
                errorMessage={'Bitte gib nur gültige Zeichen ein. Erlaubt sind Groß- und Kleinbuchstaben, sowie die Sonderzeichen ,.\'-_#()!?'}
                label="Text (optional)"
                placeholder="Alles Gute zum Geburtstag"
                maxLength={180}
                maxRows={3}
            />
            <div className="priceIndicatorAndButtonWrapper">
                <span>Aktueller Buchungsbetrag: <strong>{totalSum} €</strong></span>
                <Buttons submitButtonText="Weiter zur Vorschau"
                />
            </div>

        </form>
    </>

}